.footerCover {
    background-color: transparent;
    color: #FFFBE2;
   
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;

  }
  