video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(0.3);
  }
.cover-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75vh;
    margin: 2rem;
      
}
.cover-div img {
    width: 40%;


}

@media (max-width: 700px) {
    .cover-div img {
        width: 80%;
    }

    
}