
  
  .navbar-mobile {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #263238;
    width: 250px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    padding: 16px 16px;
    top: 0;
    right: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 8px;
    list-style: none;
    height: 60px;
    width: 100%;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #FFFBE2;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #FBC02D;
  }
  
  .nav-menu-items {
    width: 100%;
    padding: 0;
   
  }
  
  .navbar-toggle {
    background-color: #263238;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  
  .ul-right ul{
    display: flex;
  /*   flex-direction: column; */
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    width: 100%;
    padding: 0;
  }
  