.consideraciones-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: transparent;
   
}
.consideraciones-container-h2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 2rem;
   
    background-color: transparent;
}
.consideraciones-container h2{
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: #FBC02D;
    text-align: left;
    margin: 2rem;
}

.consideraciones-container-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: transparent;
    filter:blur(0px) brightness(100%) contrast(100%) grayscale(0%) opacity(100%) saturate(100%) sepia(0%); 
    flex-wrap: wrap;
    max-width: 1900px;
}

@media (max-width: 1725px) {
    .consideraciones-container-card{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background-color: transparent;
        flex-wrap: wrap;
        max-width: 900px;
    }
    
}