.Valores-div{
margin: 3rem 0rem;

}
.Valores-h2{
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: #FBC02D;
    text-align: center;
    margin: 1rem 2rem;
}
.Valores-div-cardValores-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    flex-wrap: wrap;
    max-width: 2000px;
}
.Valores-div-card{
    width: 20rem;
    height: 15rem;
    border-radius: 0.5rem ;
    margin: 1.5rem;
 
}

.Valores-img{
    width: 100%;
    height: 100%;
    filter: brightness(0.8)  contrast(60%);
    border-radius: 0.5rem;
    object-fit: cover;
}
.Valores-div-card p{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -50%;
    width: 100%;
    height: 20%;
    background-color: #607D8B;
    color: #FFFBE2;
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    filter: opacity(0.8);
    object-fit: contain;
}
@media (max-width: 1170px) {
    .Valores-div-cardValores-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background-color: transparent;
        flex-wrap: wrap;
        max-width: 1100px;
    }
}