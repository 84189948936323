.contacto-h1{
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color: #FBC02D;
    text-align: center;
}
.contacto-p{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #FFFBE2;
    text-align: center;
    margin: 1rem 0rem;
}
.contacto{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 100%;
    padding: 2rem;  
}
.contacto__form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 40rem;
    
}

.contacto__form__input{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    padding: 0rem 2rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    border: none;
}

.contacto__form__input::placeholder{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #263238;
}

.contacto__form__textarea{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10rem;
    padding: 1rem 2rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    border: none;
    resize: none;
}

.contacto__form__textarea::placeholder{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #263238;
}
.contacto__form__error{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 0.01rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: #FFFBE2;
}
.input__none{
    display: none;
}
.contacto__form__button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    min-width: 15rem;
    height: 2.5rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    border: none;
    background-color: #FBC02D;
    color: #263238;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    color: #FFFBE2;

}
.contacto__form__div__button-number{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
   flex-direction: row;
   flex-wrap: wrap;
}
.contacto-p-a{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: #FFFBE2;
    text-align: center;
    margin: 2rem 2rem;
    
    text-decoration: none;
    
}
.contacto__form__button-number:hover{
    background-color: #25d366;
    color: #FBC02D;
    transition: 0.5s;
}


