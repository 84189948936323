.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    width: 25rem;
    height: 15rem;
    border: none;
    background-color: transparent;


}

.card-image {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 50%;
    background-color: transparent;
    

}

.card-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.card-p{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 70%;
    height: 50%;
    background-color: transparent;
    margin-top: 1rem;
    
}
.card-p p{
    
    font-size: 1rem;
    font-weight: 600;
    color: #FFFBE2;
    text-align: center;
}