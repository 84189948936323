.servicios{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    
}
.servicios-h1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 2rem;
    color: #FBC02D;
    background-color: transparent;
}

.servicios__cardContainer { 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 1000px;
    
}

.servicios__cardContainer .cardHover {
    width: 24rem;
    height: 36rem;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: #FFFBE2;
    box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
    margin: 2rem;
}
.servicios_otros{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 100%;
    background-color: transparent;
}
.servicios_otros-h2, .servicios_otros h3{
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    width: 100%;
    color: #FBC02D;
    background-color: transparent;
}
.servicios_otros p{
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    width: 100%;
    color: #FFFBE2;
    background-color: transparent;
}
.servicios_otros ul{
    text-decoration: none;
    list-style: none;
    margin-bottom: 1rem;
}
.servicios_otros-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10rem;   
    color: #FFFBE2;
    background-color: #FBC02D;
    border: 1px solid #FBC02D;
    border-radius: 10px;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    margin: 2rem 0;
}

@media screen and (max-width: 768px) {
    .servicios__cardContainer .cardHover{
        transform: scale(0.8);
    }
    .servicios_otros{
        width: 80%;
    }
}
    

@media  (max-width: 480px) {
    .servicios__cardContainer .cardHover{
        transform: scale(0.8);
    }
}