.img-bg{
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(0.3) blur(0px);
}
.about-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; 
}
.About-div-sobreNosotros{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
   
}
.About-h1{
    font-family: 'Roboto', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    color: #FBC02D;
    text-align: center;
}
.About-div-p{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;   
    
}

.About-p{
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    color: #FFFBE2;
    text-align: left;
    margin: 2rem;
    
}
.About-div-nuestraMision{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
 
    max-width: fit-content;
}
.About-h2{
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: #FBC02D;
    text-align: center;
    margin: 1rem 2rem;
}
.about-p-marco{
    background-color: #263238;
    filter: opacity(0.9);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}