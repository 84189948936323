.cardHover {
  width: 24rem;
  height: 36rem;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: #FFFBE2;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
}
.cardHover__img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
    transition: opacity .2s ease-out;
    background-color: blue;
    /* filter: brightness(0.6); */
  }

.cardHover_h2 {
  position: absolute;
  inset: auto auto 30px 0px;
  margin: 0;
  transition: inset .3s .3s ease-out;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  background-color: #607D8B;
  padding: 1rem 0;
  text-align: center;
}

.cardHover_p {
  position: absolute;
  opacity: 0;
  max-width: 80%;
  transition: opacity .3s ease-out;
  inset: auto auto 80px 30px;
  padding: 1rem 1rem;
  font-family: 'Roboto', sans-serif;
  filter: opacity(0.8);
}
  
.cardHover:hover h2, .cardHover-content-clicked h2{
  inset: auto auto 300px 0px;
  transition: inset .3s ease-out;
  text-align: center;
}

.cardHover:hover p, .cardHover-content-clicked p{
  opacity: 1;
  transition: opacity .5s .1s ease-in;
  width: 100%;
 
  
}

.cardHover:hover img, .cardHover-content-clicked img{
  transition: opacity .3s ease-in;
  opacity: 1;
  filter: brightness(0.3) blur(2px);
}


  
