  .headerCover {
    background-color: transparent;
    color: #ffffff;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
  }
  .ul-right ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    list-style: none;
  
  }
  
  a {
    color: #FFFBE2;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    margin: 0 30px;
  }
  a:hover a:active a::after{
    color: #FBC02D;
    text-decoration: none;
  }
  .logo-left img{
 
    height: 40px;
   
  }
  .logo-left{
    width: 50%;
  }
.none{
  display: none;
}
@media  (max-width: 1000px) {
  .ul-right{
    display: flex;
    flex-direction: column;

  }
  .ul-right ul{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0;
    border-radius: 4px;
    list-style: none;
  }

  
}